import { validateTracking } from '@pedidosya/tracking-validator'
import { isAndroidTrack, androidTrack } from './platformTracking/androidTrack';
import { isIosTrack, iosTrack } from './platformTracking/iosTrack';
import { getWebTracker } from './platformTracking/webTrack';
import {
  getHistoricalTracker,
  getGetHistory,
} from './platformTracking/historicalTrack';

let trackingData = [];
let trackFunction = null;
let historicalTrackFunction = null;
let getHistoryFunction = null;
let common = {};

function onTrackingInitialized() {
  trackingData.forEach(({ event, payload }) => {
    trackFunction(event, payload);
  });
  trackingData = [];
}

function isInitialized() {
  return !!(trackFunction && historicalTrackFunction && getHistoryFunction);
}

export async function initialize(config = {}) {
  if (isInitialized()) return Promise.resolve();
  const { historicalEnabled = false, gtmConfig, commonData = {} } = config;
  common = { ...commonData };
  if (isAndroidTrack()) trackFunction = androidTrack;
  else if (isIosTrack()) trackFunction = iosTrack;
  else trackFunction = getWebTracker({ gtmConfig });

  historicalTrackFunction = getHistoricalTracker({ historicalEnabled });
  getHistoryFunction = getGetHistory({ historicalEnabled });
  onTrackingInitialized();
  return Promise.resolve();
}

export function track(event, data) {
  const payload = { ...common, ...data };

  if (!trackFunction) {
    trackingData.push({ event, payload });
  } else {
    trackFunction(event, payload);
    validateTracking({ event, payload });
  }

  if (historicalTrackFunction) {
    historicalTrackFunction(event, payload);
  }
}

export function getHistory() {
  if (getHistoryFunction) return getHistoryFunction();
}

// only for tests
export function clear() {
  trackingData = [];
  trackFunction = null;
  historicalTrackFunction = null;
  getHistoryFunction = null;
}
