import { NOT_SET } from '../constants';

const EXCLUDED = ['userId'];

function shouldReplaceToNotSet(key, value) {
  return !EXCLUDED.includes(key) && (typeof value === 'undefined' || value === null || value === '');
}

export default function mapPropsToTracking(data) {
  try {
    return Object.keys(data).reduce((obj, key) => {
      const value = data[key];
      // eslint-disable-next-line no-param-reassign
      obj[key] = shouldReplaceToNotSet(key, value) ? NOT_SET : value;
      return obj;
    }, {});
  } catch (err) {
    return data;
  }
}
