import formatEvent from "../utils/formatEvent";

export function iosTrack(event, payload) {
  try {
    const message = {
      command: 'logEvent',
      event: formatEvent(event),
      payload,
    };
    window?.webkit?.messageHandlers?.AnalyticsWebInterface?.postMessage(
      message,
    );
  } catch (error) {
    throw new Error('[ERROR][iosTrack]', error);
  }
}

export function isIosTrack() {
  return !!window?.webkit?.messageHandlers?.AnalyticsWebInterface;
}

