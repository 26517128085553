function sortObjectKeys(obj) {
  if (!obj) return [];
  return Object.keys(obj).sort();
}

function sortObject(obj) {
  if (!obj) return obj;
  return sortObjectKeys(obj).reduce((o, k) => {
    o[k] = obj[k];
    return o;
  }, {});
}

export default sortObject;
