import mapPropsToTracking from '../utils/mapPropsToTracking';
import { saveTrackingData, getTrackingData } from '../utils/cache';

export function getHistoricalTracker({ historicalEnabled = false }) {
  return (event, payload) => {
    try {
      if (historicalEnabled) {
        const formatedData = mapPropsToTracking({
          event,
          ...payload,
        });
        saveTrackingData(formatedData);
      }
    } catch (error) {
      throw new Error('[ERROR][historicalTracker]', error);
    }
  };
}

export function getGetHistory({ historicalEnabled = false }) {
  return () => {
    try {
      if (historicalEnabled) {
        return getTrackingData();
      }
    } catch (error) {
      throw new Error('[ERROR][getHistory]', error);
    }
  };
}
