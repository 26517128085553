import formatEvent from "../utils/formatEvent";

export function androidTrack(event, payload) {
  try {
    const dataToTrack = {
      event: formatEvent(event),
      ...payload,
    };

    const androidInterface =
      window.AnalyticsWebInterface || window.CourierWebFormInterface;

    androidInterface.logEvent(event, JSON.stringify(dataToTrack));
  } catch (error) {
    throw new Error('[ERROR][androidTrack]', error);
  }
}

export function isAndroidTrack() {
  return !!(window?.AnalyticsWebInterface || window?.CourierWebFormInterface);
}
